import React from 'react';
import ReactDOM from 'react-dom';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom'
import './App.scss';
import { ProtectedRoute } from "./molecule/protectedRoute"
import { Dashboard as AdminDashboard } from "./pages/dashboard"
import { Login as AdminLogin } from './pages/login'
import Program from './pages/program';
import Level from "./pages/level"
import { AdminContainer } from './template/shared';
import { Logout } from './atoms/logout';
import { Teachers } from './template/teacher';

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <Routes>
        <Route path="/" element={<ProtectedRoute />} >
          <Route path="" element={<AdminContainer loggedIn={true} />} >
            <Route path="" element={<AdminDashboard />}></Route>
            <Route path="program/:programId" element={<Program />}></Route>
            <Route path="level/:levelId" element={<Level />}></Route>
            <Route path="logout" element={<Logout />}></Route>
            <Route path="school/teachers/:schoolId" element={<Teachers />}></Route>
          </Route>
        </Route>
        <Route path="" element={<AdminContainer loggedIn={false} />}>
          <Route path="login" element={<AdminLogin />}></Route>
        </Route>
      </Routes>
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);